/** @format */

.invest-image-section {
  display: flex;
  flex-direction: column;
  height: 300px;
  justify-content: space-between;

  .invest-image,
  .renovate-image,
  .monitor-image {
    border-radius: 25px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 100%;
  }

  span {
    text-decoration: none;
    font-family: var(--font-inter);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: var(--color-neutral-dark-900);
  }

  .signup-course-button {
    width: 100%;
  }
}

@media screen and (max-width: 919px) {
  .invest-image-section {
    margin-top: 30px;
    height: 350px;

    .next-course {
      align-self: center;
    }

    .invest-image,
    .renovate-image,
    .monitor-image {
      display: block;
      width: 100%;
    }
  }
}
