/** @format */

.header-v2 {
  display: flex;
  justify-content: space-around;
  height: var(--height-header);
  flex-direction: row;
  align-items: end;
  padding: 0 5%;
  margin: auto;

  .header-left-container {
    max-width: 250px;
  }

  .header-middle-container {
    flex: 2;
    justify-content: flex-start;
    .header-nav-links {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 30px;

      span {
        text-decoration: none;
        cursor: pointer;
        font-family: var(--font-inter);
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 140%;
        color: var(--color-neutral-dark-900);

        &.active {
          text-decoration-line: underline;
          text-decoration-thickness: 1.5px;
          text-underline-offset: 3px;
        }

        &:hover {
          text-decoration-line: underline;
          text-decoration-thickness: 1.5px;
          text-underline-offset: 3px;
        }
      }

      .dropdown-toggle {
        text-decoration: none;
        font-family: var(--font-inter);
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 140%;
        color: var(--color-neutral-dark-900);
        padding: 0 0 0 0;

        &::after {
          display: inline !important;
        }

        &:hover {
          background-color: transparent !important;
          border-color: transparent !important;
          text-decoration-line: underline;
          text-decoration-thickness: 1.5px;
          text-underline-offset: 3px;
        }
        &:active,
        &.show {
          background-color: transparent !important;
          border-color: transparent !important;
        }
      }

      .dropdown-item:active {
        background-color: transparent !important;
      }
    }
  }

  .header-right-container {
    flex: 1;
    padding-right: 0px;

    .header-buttons-section {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      align-items: center;
    }
  }
}

.top-blur-section {
  position: absolute;
  width: 65%;
  height: var(--height-header);
  left: 18%;
  top: -5%;
  background: linear-gradient(93.26deg, #a75067 37.9%, #ffb967 100%);
  filter: blur(80px);
}

.mobile-header-overlay {
  .header-nav-links {
    gap: 5px;
  }
}

@media screen and (max-width: 919px) {
  .header-v2 {
    justify-content: left;
    height: auto;
    flex-direction: column !important;
    align-items: normal;
    margin-bottom: 30px;
    position: relative;
    z-index: 5;

    .header-left-container {
      width: 100%;
      max-width: none;

      .logo {
        justify-content: center;
        display: flex;

        .logo-fria-maklare-dark {
          margin: 56px 0 0 0;
          max-width: none;
          width: 162px;
        }
      }
    }

    .header-middle-container {
      display: none;
      // margin-top: 30px;
      // justify-content: center !important;

      // .header-nav-links {
      //   flex-direction: column !important;
      //   gap: 20px;
      // }
    }
  }
}
