/** @format */

.mobile-header-overlay {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  .header-nav-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
      margin-bottom: 10px;
      font-size: 20px !important;
    }
  }

  .logo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 10px;

    .logo-fria-maklare-dark {
      margin: 0 0 0 0;
      width: 162px;
      max-width: none;
    }
  }

  .mobile-header-button-section {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    width: 100%;
    height: 105px;
    justify-content: space-between;
  }
}
